import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../../../api";

import { copyExisting } from "../../../../services/development-plan";

import InternalModal from "../../../../commons/InternalModal";

import Option from "./Option";
import CopyItemModal from "../CopyItemModal";

import blank from "../../../../assets/icons/pdp/blank.svg";
import template from "../../../../assets/icons/pdp/template.svg";
import generate from "../../../../assets/icons/pdp/generate.svg";

const CreatePlanModal = ({ team, show, onHide }) => {

  const [loading, setLoading] = useState(false);
  const [showPlanPicker, setShowPlanPicker] = useState(false);

  const selectDocumentAndGeneratePlan = async () => {
    const document = await showDocumentChooser();
    generatePlan(document);
  }

  const showDocumentChooser = async () => {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".pdf,.doc,.docx,.docm,.xls,.xlsx,.csv,.md";
  
      input.onchange = event => {
        const file = event.target.files[0];

        if (file) {
          resolve(file);
        } else {
          reject(new Error("No file selected!"))
        }
      }
  
      input.click();
    });
  }

  const generatePlan = (document) => {
    if (!document) return;

    setLoading(true);

    const data = new FormData();
    data.append("team_uuid", team?.uuid);
    data.append("document", document);

    api.post("/development_plans/generate", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => {
        console.log(res);
        if (res.data && res.data.redirect_to) {
          window.location.assign(res.data.redirect_to);
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const showExistingPlanPicker = () => {
    onHide && onHide();
    setShowPlanPicker(true);
  }

  const handlePlanSelect = (plan) => {
    const copy = copyExisting(plan);
    const template = encodeURIComponent(JSON.stringify(copy));
    window.location = `/pdp/new?template=${template}&locale=${I18n.locale}`;
  }

  const renderModal = () => {
    return (
      <div className="pdp-create-plan-modal">
        <div className="title">
          {I18n.t("pdp.modals.create_plan.title")}
        </div>
        <div className="subtitle">
          {I18n.t("pdp.modals.create_plan.subtitle")}
        </div>
        <div className="options">
          <Option
            icon={blank}
            title={I18n.t("pdp.modals.create_plan.options.blank.title")}
            description={I18n.t("pdp.modals.create_plan.options.blank.description")}
            href={`new?locale=${I18n.locale}`}
            disabled={loading}
          />
          <Option
            icon={template}
            title={I18n.t("pdp.modals.create_plan.options.template.title")}
            description={I18n.t("pdp.modals.create_plan.options.template.description")}
            disabled={loading}
            onClick={showExistingPlanPicker}
          />
          <Option
            icon={generate}
            title={I18n.t("pdp.modals.create_plan.options.generate.title")}
            description={I18n.t("pdp.modals.create_plan.options.generate.description")}
            loading={loading}
            titleLoading="Generating.."
            onClick={selectDocumentAndGeneratePlan}
          />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <InternalModal
        type="create-plan"
        size="lg"
        isModalOpen={show}
        component={renderModal()}
        showModalBody={false}
        showCloseAction
        hideModal={onHide}
      />
      <CopyItemModal
        show={showPlanPicker}
        type="plan"
        onSelect={handlePlanSelect}
        onCancel={() => setShowPlanPicker(false)}
      />
    </Fragment>
  );
}

export default CreatePlanModal;
