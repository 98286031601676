import { useMemo } from "react";

import { getTimeLocal } from "../services/date";

const convertUTCTimeToLocal = (template) => ({
  ...template,
  time: getTimeLocal(template?.time),
});

const useTemplate = () => {

  const params = useMemo(() =>
    new URLSearchParams(window.location.search),
  [window.location.search]);

  const param = params.get("template");
  
  if (param) {
    const template = JSON.parse(decodeURIComponent(param));
    console.log("Loading template: ", template);
    return convertUTCTimeToLocal(template);
  }

  return null;
}

export default useTemplate;
