import React, { useMemo } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { ReactSVG } from "react-svg";
import moment from "moment";

import sortAscIcon from "../../assets/icons/table-sort-asc.svg";
import sortDescIcon from "../../assets/icons/table-sort-desc.svg";

const formatDate = (date) => moment.utc(date).local().format("L");

const makeTableData = (plans) => plans.map(plan => {
  const endDate = plan?.status === "completed" ? plan.completed_at : plan.target_date;
  return {
    uuid: plan.uuid,
    title: plan.title,
    timeframe: `${formatDate(plan.start_date)} - ${formatDate(endDate)}`,
    status: plan.status
  };
});

const PlansTable = ({ plans }) => {

  const columns = useMemo(() => [
    {
      id: "title",
      accessorKey: "title",
      header: () => <span>Plan Label</span>,
      cell: info => (
        <a href={`${info.row.original.uuid}?locale=${I18n.locale}`}>
          {info.getValue()}
        </a>
      ),
      sortUndefined: "last",
      sortDescFirst: false
    },
    {
      id: "timeframe",
      accessorKey: "timeframe",
      header: () => <span>Timeframe</span>,
      cell: info => info.getValue(),
      sortUndefined: "last",
      sortDescFirst: false
    },
    {
      id: "status",
      accessorKey: "status",
      header: () => <span>Status</span>,
      cell: info => (
        <span className={info.getValue()}>
          {I18n.t(`pdp.statuses.${info.getValue()}`)}
        </span>
      ),
      sortUndefined: "last",
      sortDescFirst: false
    }
  ], []);

  const table = useReactTable({
    columns,
    data: makeTableData(plans),
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  return (
    <div className="table-wrapper">
      <table>
        <thead>
          {table.getHeaderGroups().map(group => (
            <tr key={group.id}>
              {group.headers.map(({ id, colSpan, isPlaceholder, column, getContext }) => (
                <th key={id} colSpan={colSpan}>
                  {isPlaceholder ? null : (
                    <div
                      className={column.getCanSort() ? "sorting-column" : ""}
                      title={column.getCanSort()
                        ? column.getNextSortingOrder() === "asc"
                          ? "Sort ascending"
                          : column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                        : undefined
                      }
                      onClick={column.getToggleSortingHandler()}
                    >
                      {flexRender(column.columnDef.header, getContext())}
                      <div className="sorting">
                        <ReactSVG className={`icon-asc ${column.getIsSorted()}`} src={sortAscIcon} />
                        <ReactSVG className={`icon-desc ${column.getIsSorted()}`} src={sortDescIcon} />
                      </div>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center", padding: "1rem" }}>
                No development plans
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PlansTable;
