import React, { Component, Fragment, useState } from "react";
import { Toaster } from "react-hot-toast";

import useRoutingState from "../hooks/useRoutingState";

import ActionButton from "../commons/ActionButton";

import PageTitle from "./components/PageTitle";
import PlansTable from "./components/PlansTable";
import CreatePlanModal from "./components/Modal/CreatePlanModal";

import plusIcon from "../assets/icons/plus.svg";

const All = ({ team, plans, showBack }) => {
  useRoutingState();

  const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);

  const showCreatePlan = () => {
    setShowCreatePlanModal(true);
  }

  const hideCreatePlan = () => {
    setShowCreatePlanModal(false);
  }

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <PageTitle title="All Development Plans" showBack={showBack} />
      <div className="action-buttons">
        <ActionButton
          title="New Plan"
          icon={plusIcon}
          onClick={showCreatePlan}
        />
      </div>
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <PlansTable plans={plans} />
    </div>
  );

  return (
    <Fragment>
      <div className="pdp all">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <CreatePlanModal
        show={showCreatePlanModal}
        team={team}
        onHide={hideCreatePlan}
      />
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class AllComponent extends Component {
  render() {
    return <All {...this.props} />;
  }
}