import React, { useEffect, useState, useRef, createRef } from "react";
import _ from "lodash";

import { getNextQuarterHour, getTimeStr, parseTimeString } from "../services/date";

const getOptions = (twelveHour) => {
  const options = [];

  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const end = new Date(start);
  end.setDate(start.getDate() + 1);

  while (start < end) {
    options.push({
      label: getTimeStr(start.getHours(), start.getMinutes(), null, null, twelveHour),
      value: getTimeStr(start.getHours(), start.getMinutes()),
      ref: createRef()
    });
    start.setMinutes(start.getMinutes() + 15);
  }

  return options;
}

const TimeInput = ({
  className,
  id,
  label,
  placeholder,
  initialValue,
  error,
  disabled,
  slim,
  twelveHour,
  onValueSelected
}) => {
  const finalId = id || _.uniqueId();
  const options = getOptions(twelveHour);

  const [value, setValue] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showItems, setShowItems] = useState(false);

  const ref = useRef();
  const buttonRef = useRef();
  const inputRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    if (initialValue) {
      const option = findOption(initialValue);
      setValue(option?.label);
      return;
    }

    const time = getNextQuarterHour();
    const option = findOption(time);
    setValue(option?.label);
    handleValueSelected(option?.value);
  }, []);

  useEffect(() => {
    selectFirstItemThatMatchesValue(value);
  }, [value]);

  useEffect(() => {
    const handler = (event) => {
      if (showItems &&
          ref.current &&
          !ref.current.contains(event.target)) {
        setShowItems(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showItems, setShowItems]);

  useEffect(() => {
    const dropdownHeight = dropdownRef.current.clientHeight + 65;
    const windowHeight = window.innerHeight;
    const dropdownTop = buttonRef.current.getBoundingClientRect().top;

    if (windowHeight - dropdownTop < dropdownHeight) {
      dropdownRef.current.style.bottom = "100%";
      dropdownRef.current.style.top = "auto";
    } else {
      dropdownRef.current.style.bottom = "auto";
      dropdownRef.current.style.top = "100%";
    }

    dropdownRef.current.style.opacity = showItems ? "1" : "0";
  }, [showItems]);

  const findOption = (value) => {
    return options.find(option => option.value === value);
  }

  const selectInputContent = () => {
    inputRef.current?.select();
  }

  const selectFirstItemThatMatchesValue = (value) => {
    const item = options.find(option => _.startsWith(option.label, value));
    setSelectedItem(item);
    item?.ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  const handleButtonClick = (event) => {
    if (disabled) return;
    event.preventDefault();
    selectInputContent();
    setShowItems(show => !show);
  }

  const handleInputChange = (event) => {
    event.preventDefault();

    const value = event.target.value;
    setValue(value);
  }

  const handleInputBlur = () => {
    if (showItems || _.isEmpty(value)) { return; }

    const time = parseTimeString(value);
    const option = findOption(time);
    setValue(option?.label);
    handleValueSelected(option?.value);
  }

  const handleItemClick = (option) => {
    setValue(option.label);
    setShowItems(false);
    handleValueSelected(option.value);
  }

  const handleValueSelected = (value) => {
    onValueSelected && onValueSelected(value);
  }

  return (
    <div
      ref={ref}
      className={`${className} ${twelveHour ? "twelve-hour" : ""} time-input`}
    >
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div
        ref={buttonRef}
        id={finalId}
        className={`input ${error ? "input-error" : ""} ${showItems ? "open" : ""} ${disabled ? "disabled" : ""}`}
        aria-haspopup="true"
        aria-expanded={showItems ? "true" : "false"}
        onClick={handleButtonClick}
      >
        <input
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
      </div>
      <ul ref={dropdownRef} className={`dropdown-items ${showItems ? "open" : ""}`}>
        {options.map((option, index) => {
          const selected = option.value === selectedItem?.value;
          return (  
            <li
              key={index}
              ref={option.ref}
              className={`item ${selected ? "selected" : ""}`}
              onClick={() => handleItemClick(option)}
            >
              <span className="title">{option.label}</span>
            </li>
          );
        })}
      </ul>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
}

export default TimeInput;
