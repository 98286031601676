import React, { Component, Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import I18n from "i18n-js";

import api from "../api";

import useRoutingState from "../hooks/useRoutingState";
import useObjectiveState from "../hooks/useObjectiveState";

import ActionButton from "../commons/ActionButton";

import PdpSelector from "./components/PdpSelector";
import PlanSelector from "./components/PlanSelector";
import PlanTitle from "./components/PlanTitle";
import ObjectiveTimeline from "./components/ObjectiveTimeline";
import ObjectivesDetails from "./components/ObjectivesDetails";

import editIcon from "../assets/icons/edit.svg";
import publishIcon from "../assets/icons/publish.svg";
import approveIcon from "../assets/icons/approve.svg";
import completeIcon from "../assets/icons/complete.svg";

const Show = ({
  teams,
  team, 
  personal, 
  isTeamLeadOrAbove, 
  isTeamLead, 
  isPlanOwner, 
  hasSomeActivePlan, 
  plan, 
  plans 
}) => {
  useRoutingState();

  const showCompleteButton = isPlanOwner && plan?.status === "approved";
  const showPublishButton = isPlanOwner && plan?.status === "draft" && !hasSomeActivePlan;
  const showApproveButton = isTeamLead && plan?.status === "published";
  const showEditButton = isPlanOwner && (personal || ["draft", "published"].includes(plan?.status));
  const showAddTaskButton = isPlanOwner && (personal || ["draft", "published"].includes(plan?.status));
  const showTaskActions = isPlanOwner && (personal || ["draft", "published"].includes(plan?.status));
  const showTaskProgressAction = isPlanOwner && (personal || plan?.status === "approved");
  const showMyPlanSelector = isPlanOwner || isTeamLead;

  const { objective, setObjective, loading } = useObjectiveState(plan?.objectives);

  const [actionLoading, setActionLoading] = useState(false);

  const publishPlan = () => {
    setActionLoading(true);

    api.post(`/development_plans/${plan?.uuid}/publish`)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.published_successfully")}</b>);

          if (res.data && res.data.redirect_to) {
            window.location.assign(res.data.redirect_to);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setActionLoading(false);
        console.error(err);
      });
  }

  const approvePlan = () => {
    setActionLoading(true);

    api.post(`/development_plans/${plan?.uuid}/approve`)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.approved_successfully")}</b>);

          if (res.data && res.data.redirect_to) {
            window.location.assign(res.data.redirect_to);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setActionLoading(false);
        console.error(err);
      });
  }

  const completePlan = () => {
    setActionLoading(true);

    api.post(`/development_plans/${plan?.uuid}/complete`)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.completed_successfully")}</b>);

          if (res.data && res.data.redirect_to) {
            window.location.assign(res.data.redirect_to);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setActionLoading(false);
        console.error(err);
      });
  }

  const getRelativeLink = (path) => {
    return `${plan?.uuid}/${path}?show_back=true&locale=${I18n.locale}`;
  }

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <div className="selectors">
        <PdpSelector
          teams={teams}
          selectedTeam={team}
          personal={personal}
          isTeamLeadOrAbove={isTeamLeadOrAbove}
        />
        {plans?.length ? (
          <PlanSelector
            team={team}
            plans={plans}
            showMyPlan={showMyPlanSelector}
          />
        ) : null}
        <PlanTitle title={plan.title} />
      </div>
      <div className="action-buttons">
        {showApproveButton
          ? <ActionButton loading={actionLoading} title="Approve" icon={approveIcon} onClick={approvePlan} />
          : showCompleteButton
            ? <ActionButton loading={actionLoading} title="Set Plan Complete" icon={completeIcon} onClick={completePlan} />
            : showPublishButton
              ? <ActionButton loading={actionLoading} title="Publish" icon={publishIcon} onClick={publishPlan} />
              : null
        }
        {showEditButton ? (
          <ActionButton
            title="Edit Plan"
            icon={editIcon}
            href={getRelativeLink("edit")}
          />
        ) : null}
        <ActionButton
          title="Summary"
          href={getRelativeLink("summary")}
        />
        {isPlanOwner ? (
          <ActionButton
            title="All Plans"
            href={`all?show_back=true&locale=${I18n.locale}`}
          />
        ) : null}
      </div>
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <ObjectiveTimeline objective={objective} />
      <div className="objectives-panel">
        <span className="section-title">Objectives</span>
        <ObjectivesDetails
          plan={plan}
          objectives={plan?.objectives}
          selectedObjective={objective}
          isPlanOwner={isPlanOwner}
          showAddTaskButton={showAddTaskButton}
          showTaskActions={showTaskActions}
          showTaskProgressAction={showTaskProgressAction}
          onObjectiveSelect={setObjective}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="pdp">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class ShowComponent extends Component {
  render() {
    return <Show {...this.props} />;
  }
}