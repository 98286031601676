import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../../api";

import InternalModal from "../../../commons/InternalModal";
import LoadingButton from "../../../commons/LoadingButton";
import DateInput from "../../../commons/DateInput";
import Textarea from "../../../commons/Textarea";

import taskSchema from "../../config/validation/task";

const getDefaultValues = (operation, task) => ({
  content: task?.content || "",
  target_date: operation === "edit" ? task?.target_date : null,
});

const EditTaskModal = ({ operation, plan, objective, task, show, onSave, onCancel }) => {

  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(() => getDefaultValues(operation, task), [operation, task]);
  const { control, reset, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(taskSchema),
    context: {
      start_date: Date.parse(plan?.start_date),
      end_date: Date.parse(plan?.target_date)
    }
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const saveTask = (data) => {
    setLoading(true);

    if (operation === "edit") {
      updateTask(data);
      return;
    }

    createTask(data);
  }

  const createTask = (data) => {
    api.post(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          onSave && onSave();

          const task = res.data.task;
          reload(task?.index);
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const updateTask = (data) => {
    api.put(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks/${task?.uuid}`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          onSave && onSave();
          reload(task?.index);
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const reload = (taskIndex) => {
    const params = new URLSearchParams(window.location.search);
    params.set("objective", objective?.index);
    params.set("task", taskIndex);
    window.location.search = params;
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-edit-task-modal">
        <form onSubmit={handleSubmit(saveTask)}>
          <div className="title">
            {operation === "edit"
              ? I18n.t("pdp.modals.edit_task.title.edit")
              : I18n.t("pdp.modals.edit_task.title.create")
            }
          </div>
          <div className="subtitle">
            {operation === "edit"
              ? I18n.t("pdp.modals.edit_task.subtitle.edit")
              : I18n.t("pdp.modals.edit_task.subtitle.create")
            }
          </div>
          <Controller
            name="content"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <Textarea
                rows={3}
                label={I18n.t("pdp.modals.edit_task.inputs.content.label")}
                placeholder={I18n.t("pdp.modals.edit_task.inputs.content.placeholder")}
                title={props.value}
                error={errors.content?.message}
                inputRef={ref}
                {...props}
              />
            )}
          />
          <Controller
            name="target_date"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <DateInput
                label={I18n.t("pdp.modals.edit_task.inputs.target_date.label")}
                placeholder={I18n.t("pdp.modals.edit_task.inputs.target_date.placeholder")}
                error={errors.target_date?.message}
                inputRef={ref}
                {...props}
              />
            )}
          />
          <div className="buttons">
            <button
              className="cancel-btn"
              disabled={loading}
              onClick={handleCancelClick}
            >
              {I18n.t("common.cancel")}
            </button>
            <LoadingButton
              filled
              type="submit"
              loading={loading}
              className="save-btn"
              title={I18n.t("pdp.modals.edit_task.buttons.save.title")}
            />
          </div>
        </form>
      </div>
    );
  }

  return (
    <InternalModal
      type="edit-task"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default EditTaskModal;
