import _ from "lodash";

const KEYS_NOT_NEEDED = [
  "id",
  "uuid",
  "status",
  "progress",
  "user_id",
  "team_id",
  "target_date",
  "start_date",
  "end_date",
  "ending_on",
  "created_at",
  "updated_at",
  "deleted_at",
  "started_at",
  "completed_at",
  "published_at",
  "approved_at"
];

const removeKeysDeep = (obj, keys) => {
  if (_.isArray(obj)) {
    return obj.map(item => removeKeysDeep(item, keys));
  } else if (_.isObject(obj)) {
    const omitted = _.omit(obj, keys);
    return _.mapValues(omitted, value => removeKeysDeep(value, keys));
  }
  return obj;
}


export const copyExisting = (plan) => {
  return removeKeysDeep(plan, KEYS_NOT_NEEDED);
}
