import React from "react";
import { ReactSVG } from "react-svg";

import chevronIcon from "../../../../assets/icons/chevron-right.svg";

const Item = ({ item, selected, expandable, loading, disabled, onSelected }) => {

  const generateItemLabel = () => {
    if (!item) return "";
    const label = item.content || item.title;
    return item.index ? `${item.index}. ${label}` : label;
  }

  const handleSelected = () => {
    if (loading || disabled) return;
    onSelected && onSelected(item);
  }

  return (
    <div
      className={`
        item
        ${selected ? "selected" : ""} 
        ${selected && expandable ? "expandable" : ""} 
        ${loading ? "loading" : ""} 
        ${disabled ? "disabled" : ""}
      `}
      onClick={handleSelected}
    >
      <span className="label">{generateItemLabel()}</span>
      {expandable ? <ReactSVG className="icon" src={chevronIcon} /> : null}
    </div>
  );
}

export default Item;
