import React, { Fragment } from "react";
import { ReactSVG } from "react-svg";

import Loading from "./Loading";

const Option = ({ 
  icon,
  title,
  description,
  loading,
  titleLoading,
  disabled,
  href,
  onClick
}) => {

  const renderContent = () => (
    <Fragment>
      <div className="icon-wrapper">
        <ReactSVG className="icon" src={icon} />
        <Loading />
      </div>
      <span className="title">{loading ? titleLoading : title}</span>
      <span className="description">{description}</span>
    </Fragment>
  );

  if (href) {
    return (
      <div className={`option-wrapper ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`}>
        <a
          className={`option ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`}
          href={href}
        >
          {renderContent()}
        </a>
      </div>
    );
  }

  return (
    <div className={`option-wrapper ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`}>
      <button
        className={`option ${loading ? "loading" : ""}`}
        disabled={disabled}
        onClick={onClick}  
      >
        {renderContent()}
      </button>
    </div>
  );
}

export default Option;
