import React, { createRef, useEffect, useRef, useState } from "react";
import _ from "lodash";

import { MAX_TASKS } from "../../config/constants";

import Textarea from "../../../commons/Textarea";
import DateInput from "../../../commons/DateInput";
import MultipleActionButton from "../../../commons/MultipleActionButton";

import Input from "../Input";
import RemoveButton from "./RemoveButton";

import plusIcon from "../../../assets/icons/plus.svg"

export const defaultTask = (index) => ({
  index: index,
  content: "",
  target_date: null
});

const Objective = ({
  objective,
  showRemove,
  unmounting,
  errors,
  onChange,
  onRemove,
  onErrorClear,
  onCopyExistingTaskClick
}) => {
  
  const [obj, setObj] = useState(objective);

  const ref = useRef();

  const getTaskError = () => {
    const task = errors?.tasks?.find(task => task);
    return task?.index?.message || task?.content?.message || task?.target_date?.message;
  }

  const error = errors?.index?.message
    || errors?.title?.message
    || errors?.tasks?.message
    || getTaskError();

  useEffect(() => {
    if (unmounting && ref.current) {
      ref.current.classList.add("unmount");
    }
  }, [unmounting]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.classList.add("show");
      }
    }, 15);
  }, [ref.current]);

  useEffect(() => {
    setObj(objective);
  }, [objective]);

  const changeObjective = (objective) => {
    setObj(objective);
    onChange && onChange(objective);
  }

  const handleTitleChange = (event) => {
    changeObjective({ ...obj, title: event.target.value });
    onErrorClear && onErrorClear("title");
  }

  const handleTaskChange = (task, key, value, index = null) => {
    const tasks = obj.tasks.map(t => {
      if (t.index === task.index) {
        return {
          ...t,
          [key]: value
        };
      }

      return t;
    });

    changeObjective({ ...obj, tasks });

    if (index !== null) {
      onErrorClear && onErrorClear(`tasks[${index}].${key}`);
    }
  }

  const handleCopyExistingTaskClick = () => {
    onCopyExistingTaskClick && onCopyExistingTaskClick(objective);
  }

  const handleCreateNewTaskClick = () => {
    const task = _.maxBy(obj.tasks, task => task.index);
    const index = (task?.index || 0) + 1;
    const tasks = [...obj.tasks, defaultTask(index)];
    changeObjective({ ...obj, tasks });
  }

  const handleRemoveClick = () => {
    onRemove && onRemove(obj);
  }

  const handleTaskRemoveClick = (task) => {
    handleTaskChange(task, "unmounting", true);
    setTimeout(() => {
      const tasks = obj.tasks
        .filter(t => t.index !== task.index)
        .map((t, index) => ({ ...t, index: index + 1 }));

      changeObjective({ ...obj, tasks });
    }, 300);
  }

  const renderTask = (task, index) => {
    const taskRef = createRef();
    
    setTimeout(() => {
      if (taskRef.current) {
        taskRef.current.classList.add("show");
      }
    }, 15);
    
    return (
      <div ref={taskRef} className={`column ${task.unmounting ? "unmount" : ""}`} key={task.index}>
        <div className="separator"></div>
        <div className="header">
          <span className="title">{`Task ${task.index}`}</span>
          <RemoveButton show onClick={() => handleTaskRemoveClick(task)} />
        </div>
        <div className="row show">
          <Textarea
            slim
            rows={3}
            placeholder="Content"
            title={task.content}
            value={task.content}
            error={errors?.tasks && errors?.tasks[index]?.content?.message}
            onChange={event => handleTaskChange(task, "content", event.target.value, index)}
          />
        </div>
        <div className="row show">
          <DateInput
            slim
            placeholder="Target date"
            value={task.target_date}
            error={errors?.tasks && errors?.tasks[index]?.target_date?.message}
            onChange={event => handleTaskChange(task, "target_date", event.target.value, index)}
          />
          <div className="dummy" />
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className="objective">
      <div className="header">
        <span className="title">{`Objective ${objective.index}`}</span>
        <RemoveButton show={showRemove} onClick={handleRemoveClick} />
      </div>
      <div className="fields">
        <div className="row show">
          <Input
            slim
            placeholder="Objective"
            title={obj.title}
            value={obj.title}
            error={errors?.title?.message}
            onChange={handleTitleChange}
          />
        </div>
        {obj.tasks.map(renderTask)}
      </div>
      <div className="pdp-form-row">
        <MultipleActionButton
          title="Add Task"
          icon={plusIcon}
          actions={[
            {
              title: "Copy existing",
              onClick: handleCopyExistingTaskClick
            },
            {
              title: "Create new",
              onClick: handleCreateNewTaskClick
            }
          ]}
          disabled={obj.tasks.length >= MAX_TASKS}
        />
        <div className="error">
          {error}
        </div>
      </div>
    </div>
  );
}

export default Objective;
