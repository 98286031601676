import React from "react";

const Loading = () => (
  <div className="loading">
    <div className="anim-row">
      {[1, 2, 3, 4].map(item => <div key={item} className="dot" />)}
    </div>
  </div>
);

export default Loading;
