import React from "react";
import moment from "moment";
import I18n from "i18n-js";

import ProgressBar from "../../../commons/ProgressBar";
import Viewer from "../../../commons/Editor/Viewer";

import Indicator from "./Indicator";
import TaskHistoryItem from "./TaskHistoryItem";

const formatDate = (date) => moment.utc(date).local().format("L");

const Timestep = ({ task, isPlanOwner }) => {

  const hasSomeInfo = task?.history?.length || task?.note;
  const progress = task?.progress ? Math.min(task.progress * 100, 100) : 0;

  const date = task?.status === "completed"
    ? formatDate(task?.completed_at)
    : `Due: ${formatDate(task?.target_date)}`;

  return (
    <div className="timestep">
      <div className="indicator-wrapper">
        <Indicator task={task} />
      </div>
      <div className="card">
        <div className="info">
          <div className={`basic ${hasSomeInfo ? "" : "no-info"}`}>
            <span className="title">{task?.content}</span>
            <span className="date">{date}</span>
          </div>
          <div className="task-progress">
            <ProgressBar value={progress} />
            <span className="indicator">
              {`${progress}% Complete`}
            </span>
          </div>
        </div>
        {task?.history?.length ? (
          <div className="section">
            <span className="title">Task History</span>
            <div className="items">
              {task?.history?.map((item, index) => (
                <TaskHistoryItem key={index} item={item} />
              ))}
            </div>
          </div>
        ) : null}
        {task?.note ? (
          <div className="section">
            <span className="title">
              {isPlanOwner ? I18n.t("pdp.my_notes") : I18n.t("pdp.notes")}
            </span>
            <Viewer note={task?.note} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Timestep;
