import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import I18n from "i18n-js";

import closeIcon from "../assets/icons/close.svg";

export default class InternalModal extends Component {
  render() {
    const { type, size, isModalOpen, hideModal, showModalBody, showCloseAction } = this.props;

    return (
      <Modal
        className={`internal-modal ${type}-modal`}
        size={size || "lg"}
        show={isModalOpen}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        contentClassName={`${type}-content`}
      >
        {showCloseAction ? (
          <button
            className="close-action-btn"
            title={I18n.t("common.actions.close")}
            aria-label={I18n.t("common.actions.close")}
            onClick={hideModal}
          >
            <ReactSVG className="icon" src={closeIcon} />
          </button>
        ) : null}
        {showModalBody === undefined || showModalBody ? (
          <Modal.Body className={`${type}-body`}>
            {this.props.component}
          </Modal.Body>
        ) : (
          this.props.component
        )}
      </Modal>
    );
  }
}
