import { Group, Rect, Text, Circle } from "konva";
import moment from "moment";

import Indicator, { indicatorSize } from "./Indicator";

const topBottomPadding = 10;
const leftRightPadding = 12;
const gap = 4;
const maxTitleWidth = 196;
const maxTitleHeight = 28;
const indicatorX = 11;
const rectX = 41;
const debug = false;

const Timestep = ({ x, y, task, indicatorClass, fontFamily, clickable, onClick }) => {

  const formatDate = (date) => moment.utc(date).local().format("L");

  const completed = task?.status === "completed";
  const inProgress = task?.status === "in_progress";

  const group = new Group();

  const titleAttrs = {
    text: task?.content,
    fontSize: 14,
    fontFamily: fontFamily,
    fontStyle: "600",
    fill: "#5C5C5C",
    align: "left",
    ellipsis: true
  };

  const titleX = x  + rectX + leftRightPadding;
  const titleY = y + topBottomPadding;

  const tempText = new Text(titleAttrs);

  const titleWidth = Math.min(tempText.width(), maxTitleWidth);
  const titleHeight = tempText.width() > maxTitleWidth
    ? maxTitleHeight
    : tempText.height();

  const title = new Text({
    ...titleAttrs,
    x: titleX,
    y: titleY,
    width: titleWidth,
    height: titleHeight,
    listening: false
  });
  group.add(title);
  
  const dateX = x + rectX + leftRightPadding;
  const dateY = titleY + title.height() + gap;

  const dateText = completed
    ? formatDate(task?.completed_at)
    : task?.target_date
      ? `Due: ${formatDate(task.target_date)}`
      : task?.started_at
        ? formatDate(task.started_at)
        : "Not started";

  const date = new Text({
    x: dateX,
    y: dateY,
    text: dateText,
    fontSize: 12,
    fontFamily: fontFamily,
    fontStyle: "400",
    fill: "#5C5C5C",
    align: "left",
    listening: false
  });
  group.add(date);

  const maxContentWidth = Math.max(title.width(), date.width());
  const width = maxContentWidth + 2 * leftRightPadding;
  const height = dateY + date.height() + topBottomPadding - y;

  const rect = new Rect({
    x: x + rectX,
    y: y,
    width: width,
    height: height,
    fill: inProgress ? "#F3F5F9" : "transparent",
    cornerRadius: 4,
  });
  group.add(rect);
  rect.moveToBottom();

  const indicatorY = y + (height - indicatorSize) / 2;
  const indicatorAttrs = {
    x: x + indicatorX,
    y: indicatorY,
    status: task?.status
  };

  const indicator = indicatorClass
    ? new indicatorClass(indicatorAttrs)
    : new Indicator(indicatorAttrs);
  indicator.then(indicator => group.add(indicator));

  // Event Handlers
  const handleMouseEnter = () => {
    if (clickable === false) { return; }

    const container = group.getStage()?.container();
    if (container?.style) {
      container.style.cursor = "pointer";
    }
  };

  const handleMouseLeave = () => {
    if (clickable === false) { return; }
    
    const container = group.getStage()?.container();
    if (container?.style) {
      container.style.cursor = "default";
    }
  };

  const handleClick = () => {
    if (clickable === false) { return; }
    onClick && onClick(task);
  };

  rect.on("mouseenter", handleMouseEnter);
  rect.on("mouseleave", handleMouseLeave);
  rect.on("click", handleClick);

  group.x(x);
  group.y(y);
  group.offsetX(x);
  group.offsetY(y);
  group.width(rectX + width);
  group.height(height);

  if (debug) {
    const debugRect = new Rect({
      x: group.x(),
      y: group.y(),
      width: group.width(),
      height: group.height(),
      fill: "yellow"
    });
  
    group.add(debugRect);
    debugRect.moveToBottom();
  
    const origin = new Circle({
      x: group.offsetX(),
      y: group.offsetY(),
      radius: 2,
      fill: "#000000",
    });
    group.add(origin);
  }
  
  return group;
};

export default Timestep;
