import I18n from "i18n-js";
import * as moment from "moment";
import _ from "lodash";

export const getHumanDate = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "days"))) {
    return date.format("L");
  }
  
  return date.fromNow();
}

export const getHumanDateTime = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "year"))) {
    return date.format("MMMM Do, YYYY h:mm a");
  }
  
  return date.format("MMMM Do, h:mm a");
}

export const getDateUTCAtMidnight = (src) => {
  if (!src) { return null; }
  
  const date = Date.UTC(
    src.getUTCFullYear(), 
    src.getUTCMonth(), 
    src.getUTCDate()
  );
  return new Date(date);
}

export const getTimeUTC = (src) => {
  if (!src) { return null; }
  const date = parseTimeStringToDate(src);
  return getTimeStr(date.getUTCHours(), date.getUTCMinutes());
}

export const getTimeLocal = (utc) => {
  if (!utc) { return null; }
  const date = parseTimeStringToDate(utc);

  const offset = date.getTimezoneOffset();

  const timestamp = date.getTime() - offset * 60 * 1000;
  const local = new Date(timestamp);
  
  return getTimeStr(local.getHours(), local.getMinutes());
}

export const parseTimeString = (time, twelveHour = false) => {
  const date = parseTimeStringToDate(time);
  return getTimeStr(date.getHours(), date.getMinutes(), null, null, twelveHour);
}

export const getNextQuarterHour = (twelveHour = false) => {
  const now = new Date();
  const minutes = now.getMinutes();
  const nextQuarterMinutes = Math.ceil(minutes / 15) * 15;

  if (nextQuarterMinutes === 60) {
    now.setHours(now.getHours() + 1, 0);
  } else {
    now.setMinutes(nextQuarterMinutes);
  }

  return getTimeStr(now.getHours(), now.getMinutes(), null, null, twelveHour);
}

export const getTimeStr = (hours, minutes, seconds = null, milli = null, twelveHour = false) => {
  let h = twelveHour ? hours % 12 : hours;
  h = h === 0 ? 12 : h;
  
  let str = getStringPaddedNumber(h);
  str += `:${getStringPaddedNumber(minutes)}`;

  if (seconds) {
    str += `:${getStringPaddedNumber(seconds)}`;
  }

  if (milli) {
    str += `:${getStringPaddedNumber(milli)}`;
  }

  if (twelveHour) {
    const ampm = hours >= 12 ? "pm" : "am";
    str += ampm;
  }

  return str;
}

const parseTimeStringToDate = (time) => {
  if (!time) return null;

  const isPM = time.toLowerCase().endsWith("pm");
  const isAM = time.toLowerCase().endsWith("am");

  const normalized = time.replace(/(am|pm)/i, "").trim();

  const [hours, minutes, seconds] = normalized.split(":") || [];
  let parsedHours = _.toNumber(hours);

  if (isPM && parsedHours !== 12) {
    parsedHours += 12;
  } else if (isAM && parsedHours === 12) {
    parsedHours = 0;
  }

  const date = new Date();
  date.setHours(
    _.defaultTo(parsedHours, 0),
    _.defaultTo(_.toNumber(minutes), 0),
    _.defaultTo(_.toNumber(seconds), 0)
  );

  return date;
}

const getStringPaddedNumber = (number) => {
  return number.toString().padStart(2, "0");
}