import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import I18n from "i18n-js";
import _ from "lodash";

import api from "../../../../api";

import InternalModal from "../../../../commons/InternalModal";
import LoadingButton from "../../../../commons/LoadingButton";

import ItemList from "./ItemList";
import Divider from "./Divider";

const CopyItemModal = ({ show, type, onSelect, onCancel }) => {

  const [plans, setPlans] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingPlansDebounced] = useDebounce(loadingPlans, 15);

  const [selectedObjective, setSelectedObjective] = useState(null);
  const [loadingObjectives, setLoadingObjectives] = useState(false);
  const [loadingObjectivesDebounced] = useDebounce(loadingObjectives, 15);

  const [selectedTask, setSelectedTask] = useState(null);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [loadingTasksDebounced] = useDebounce(loadingTasks, 15);

  const task = type === "task";
  const objective = type === "objective";

  const selected = task
    ? selectedTask
    : objective
      ? selectedObjective
      : selectedPlan;

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    try {
      setLoadingPlans(true);
      const res = await api.get("/development_plans");
      const plans = res?.data?.plans;
      setPlans(plans);
      setLoadingPlans(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      setLoadingPlans(false);
      console.error(err);
    }
  }

  const getPlan = async (uuid) => {
    try {
      const res = await api.get(`/development_plans/${uuid}`);
      return res?.data?.plan;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      console.error(err);
    }
  }

  const getObjectives = async (plan) => {
    try {
      setLoadingObjectives(true);
      const res = await api.get(`/development_plans/${plan?.uuid}/objectives`);
      const objectives = res?.data?.objectives;
      setObjectives(objectives);
      setLoadingObjectives(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      setLoadingObjectives(false);
      console.error(err);
    }
  }

  const getTasks = async (objective) => {
    try {
      setLoadingTasks(true);
      const res = await api.get(`/development_plans/${selectedPlan?.uuid}/objectives/${objective?.uuid}/tasks`);
      const tasks = res?.data?.tasks;
      setTasks(tasks);
      setLoadingTasks(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      setLoadingTasks(false);
      console.error(err);
    }
  }

  const reset = () => {
    setLoadingPlans(false);
    setLoadingObjectives(false);
    setSelectedPlan(null);
    setSelectedObjective(null);
    setSelectedTask(null);
  }

  const handlePlanSelected = async (plan) => {
    if (selectedPlan === plan) return;
    setSelectedPlan(plan);
    await getObjectives(plan);
  }

  const handleObjectiveSelected = async (objective) => {
    if (selectedObjective === objective) return;
    setSelectedObjective(objective);
    await getTasks(objective);
  }

  const handleSelectClick = async () => {
    let item = selected;

    if (!task && !objective) {
      item = await getPlan(selected?.uuid);
    }

    onSelect && onSelect(item);
    reset();
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    reset();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-copy-item-modal">
        <div className="title">
          {task
            ? I18n.t("pdp.modals.copy_item.title.task")
            : objective
              ? I18n.t("pdp.modals.copy_item.title.objective")
              : I18n.t("pdp.modals.copy_item.title.plan")
          }
        </div>
        <div className="subtitle">{I18n.t("pdp.modals.copy_item.subtitle")}</div>
        <div className="lists">
          <ItemList
            title="Development Plans"
            expandable
            items={plans}
            loading={loadingPlansDebounced}
            onItemSelected={handlePlanSelected}
          />
          <Divider show={Boolean(selectedPlan)} />
          {selectedPlan ? (
            <ItemList
              title="Objectives"
              expandable
              items={objectives}
              loading={loadingObjectivesDebounced}
              onItemSelected={handleObjectiveSelected}
            />
          ) : (
            <div className="list-dummy" />
          )}
          <Divider show={Boolean(selectedObjective)} />
          {selectedObjective ? (
            <ItemList
              title="Tasks"
              items={tasks}
              loading={loadingTasksDebounced}
              disabled={!task}
              onItemSelected={setSelectedTask}
            />
          ) : (
            <div className="list-dummy" />
          )}
        </div>
        <div className="buttons">
          <button
            className="cancel-btn"
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <LoadingButton
            filled
            disabled={!Boolean(selected)}
            className="select-btn"
            title={I18n.t("pdp.modals.copy_item.buttons.select.title")}
            onClick={handleSelectClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="copy-item"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default CopyItemModal;
