import React, { Component, Fragment, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import I18n from "i18n-js";
import * as moment from "moment";

import api from "../api";

import Card from "./components/Card";

const Processing = () => {
  const params = new URLSearchParams(window.location.search);
  const isTeams = params.has("teams");
  const sessionId = params.get("session_id");

  const tierStatusInterval = useRef(null);

  useEffect(() => {
    awaitActivate();
    return () => clearTierStatusInterval();
  }, []);

  const awaitActivate = () => {
    const promise = new Promise((resolve, reject) => {
      tierStatusInterval.current = setInterval(() =>
        isTeams
          ? verifyActivation(resolve, reject)
          : verifyUserTier(resolve, reject),
        5000
      );
    }); 

    toast.promise(promise, {
      loading: I18n.t("plans.processing"),
      success: I18n.t("plans.success"),
      error: (err) =>
        err.response && err.response.data.message || err.message
    });
  }

  const verifyUserTier = async (resolve, reject) => {
    try {
      const res = await api.get("/users/me");
      if (res.data && res.data.status === "success") {
        const { tier } = res.data.data;

        if (["trial", "paid"].includes(tier)) {
          resolve();
          clearTierStatusInterval();
          window.location.assign("/");
        }
      }
    } catch (err) {
      console.error(err);
      reject(err);
      clearTierStatusInterval();
    }
  }

  const verifyActivation = async (resolve, reject) => {
    try {
      const res = await api.get(`/plans/checkout_status?session_id=${sessionId}`);
      if (res.data && res.data.status === "success") {
        const { team_uuid } = res.data.data;
        resolve();
        clearTierStatusInterval();
        window.location.assign(`/teams/${team_uuid}/dashboard?locale=${I18n.locale}`);
      }
    } catch (err) {
      console.error(err);
      reject(err);
      clearTierStatusInterval();
    }
  }

  const clearTierStatusInterval = () => {
    if (tierStatusInterval.current) {
      clearInterval(tierStatusInterval.current);
    }
  }

  return (
    <Fragment>
      <div className="processing">
        {/*isTeams ? (
          <Card
            title={I18n.t("plans.team_coach_pro.processing.title")}
            content={I18n.t("plans.team_coach_pro.processing.content")}
          />
        ) : null*/}
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class ProcessingComponent extends Component {
  render() {
    return <Processing {...this.props} />;
  }
}
