import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import I18n from "i18n-js";
import * as moment from "moment";
import _ from "lodash";

import api from "../api";

import Input from "../commons/Input";
import Button from "../commons/Button";
import Navigation from "../commons/Navigation";

const NewUser = () => {
  const params = new URLSearchParams(window.location.search);
  const sessionId = params.get("session_id");

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState(null);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);

  const tierStatusInterval = useRef(null);

  useEffect(() => {
   awaitActivate();
   return () => clearTierStatusInterval();
  }, []);

  const awaitActivate = () => {
    const promise = new Promise((resolve, reject) => {
      tierStatusInterval.current = setInterval(() =>
        verifyActivation(resolve, reject),
        5000
      );
    }); 

    toast.promise(promise, {
      loading: I18n.t("plans.processing"),
      success: I18n.t("plans.success"),
      error: (err) =>
        err.response && err.response.data.message || err.message
    });
  }

  const verifyActivation = async (resolve, reject) => {
    try {
      const res = await api.get(`/plans/checkout_status?session_id=${sessionId}`);
      if (res.data && res.data.status === "success") {
        const { first_name } = res.data.data;

        setFirstName(first_name);
        setShowForm(true);

        resolve();
        clearTierStatusInterval();
      }
    } catch (err) {
      console.error(err);
      reject(err);
      clearTierStatusInterval();
    }
  }

  const setUserPassword = async () => {
    try {
      setLoading(true);
      const data = { session_id: sessionId, password };
      const res = await api.post("/users/set_password", data);
      if (res.data && res.data.status === "success") {
        window.location.assign(`/?locale=${I18n.locale}`);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        const message = err.response.data.message;
        toast.error(<b>{message}</b>);

        if (err.response.status === 422) {
          setPasswordError(message);
        }
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      setLoading(false);
      console.error(err);
    }
  }

  const clearTierStatusInterval = () => {
    if (tierStatusInterval.current) {
      clearInterval(tierStatusInterval.current);
    }
  }

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    setPasswordError(null);
  }

  const handlePasswordKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handleSubmit();
    }
  }

  const handleSubmit = async () => {
    if (_.isEmpty(password)) {
      setPasswordError(I18n.t("new_password.inputs.password.errors.blank"));
      return;
    }

    if (_.isNull(sessionId) || _.isEmpty(sessionId)) {
      toast.error(I18n.t("new_password.errors.invalid_session_id"));
      return;
    }

    await setUserPassword();
  }

  return (
    <Fragment>
      {showForm ? (
        <div className="page">
          <Navigation fixed />
          <div className="form-container">
            <div className="form-card">
              <h1>{I18n.t("new_password.title", { first_name: firstName })}</h1>
              <p>{I18n.t("new_password.subtitle")}</p>
              <Input
                label={I18n.t("new_password.inputs.password.label")}
                value={password}
                error={passwordError}
                type="password"
                onChange={handlePasswordChange}
                onKeyPress={handlePasswordKeyPress}
              />
              <Button
                type="submit"
                title={I18n.t("new_password.submit")}
                loading={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      ) : null}
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NewUserComponent extends Component {
  render() {
    return <NewUser {...this.props} />;
  }
}
