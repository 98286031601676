import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";

import DateInput from "../../../commons/DateInput";
import TimeInput from "../../../commons/TimeInput";
import SelectInput from "../../../commons/SelectInput";
import RadioGroup from "../../../commons/RadioGroup";

import Input from "../Input";
import ChipGroup from "../ChipGroup";
import FormSection from "./FormSection";

const TimeWithVicky = () => {

  const { control, watch, setValue, defaultValues, formState: { errors } } = useFormContext();

  const error = errors.time
    || errors.frequency
    || errors.periodicity
    || errors.repeat
    || errors.ending?.type
    || errors.ending?.on
    || errors.ending?.after;

  const today = moment().format("YYYY-MM-DD");

  const renderEndDateOption = () => (
    <Controller
      name="ending.on"
      control={control}
      render={({ field: { ref, ...props } }) => (
        <DateInput
          slim
          className="pdp-radio-button-date"
          min={today}
          placeholder="End Date"
          error={errors?.ending?.on?.message}
          disabled={watch("ending.type") !== "on"}
          inputRef={ref}
          {...props}
        />
      )}
    />
  );

  const renderEndOccurencesOption = () => (
    <div className="pdp-radio-button-occurences">
      <Controller
        name="ending.after"
        control={control}
        render={({ field: { ref, ...props } }) => (
          <Input
            slim
            type="number"
            min="1"
            error={errors?.ending?.after?.message}
            disabled={watch("ending.type") !== "after"}
            inputRef={ref}
            {...props}
          />
        )}
      />
      occurence(s)
    </div>
  );

  return (
    <FormSection
      title="Time with Vicky"
      description="Ensure success by setting recurring time to chat with Vicky and reach your goals."
    >
      <div className="pdp-form-item">
        <div className="pdp-form-row">
          Schedule time
          <TimeInput
            slim
            twelveHour={true}
            className="pdp-input-meet-time"
            initialValue={defaultValues.time}
            error={errors.time}
            onValueSelected={value => setValue("time", value)}
          />
          Meet every
          <SelectInput
            slim
            className="pdp-select-meet-frequency"
            options={[...Array(12).keys()].map(key => ({
              label: `${key + 1}`,
              value: `${key + 1}`
            }))}
            initialValue={defaultValues.frequency}
            error={errors.frequency}
            onValueSelected={value => setValue("frequency", value)}
          />
          <SelectInput
            slim
            className="pdp-select-meet-periodicity"
            options={[
              { label: "Week(s)", value: "week" },
              { label: "Month(s)", value: "month" },
            ]}
            initialValue={defaultValues.periodicity}
            error={errors.periodicity}
            onValueSelected={value => setValue("periodicity", value)}
          />
        </div>
      </div>
      <div className="pdp-form-item">
        <span className="pdp-form-item-title">Repeat on</span>
        <ChipGroup
          multiSelect
          options={[
            {
              label: "Mon",
              value: "monday"
            },
            {
              label: "Tues",
              value: "tuesday"
            },
            {
              label: "Wed",
              value: "wednesday"
            },
            {
              label: "Thurs",
              value: "thursday"
            },
            {
              label: "Fri",
              value: "friday"
            }
          ]}
          value={watch("repeat")}
          error={errors.repeat}
          onValueChange={value => setValue("repeat", value)}
        />
      </div>
      <div className="pdp-form-item last-item">
        <span className="pdp-form-item-title">Ends</span>
        <RadioGroup
          slim
          items={[
            { label: "When I end it", value: "manual" },
            {
              label: "On",
              view: renderEndDateOption(),
              value: "on"
            },
            {
              label: "After",
              view: renderEndOccurencesOption(), 
              value: "after"
            },
          ]}
          initialValue={defaultValues.ending.type}
          onItemSelected={item => setValue("ending.type", item.value)}
        />
      </div>
      <div className="pdp-form-error">
        {error?.message}
      </div>
    </FormSection>
  );
}

export default TimeWithVicky;
