import { object, string, date, array, number } from "yup";

import { getDateUTCAtMidnight } from "../../../services/date";

const planSchema = object({
  title: string().required("Label is required"),
  start_date: date().required("Start date is required"),
  target_date: date()
    .required("End date is required")
    .test("is-after-start-date", "End date must be after start date", (target_date, context) => {
      const { start_date } = context.parent;
      return target_date && start_date && target_date > start_date;
    }),
  objectives: array().of(
    object({
      index: number().required("Objective index is required"),
      title: string().required("Objective title is required"),
      tasks: array().of(
        object({
          index: number().required("Task index is required"),
          content: string().required("Task content is required"),
          target_date: date()
            .required("Task target date is required")
            .test(
              "is-target-date-valid",
              "Target date must be between the development plan start and end dates",
              (target_date, context) => {
                const plan = context.from[2].value;
                const start_date = new Date(plan?.start_date);
                const end_date = new Date(plan?.target_date);
                const date = getDateUTCAtMidnight(target_date);

                if (date && start_date && end_date) {
                  return date >= start_date && date <= end_date;
                }

                return false;
              }
            )
        })
      ).required("At least one task is required")
       .min(1, "At least one task is required")
    })
  ).required("At least one objective is required")
   .min(1, "At least one objective is required"),
  time: string()
    .required("The schedule time is required")
    .matches(/^(?:[01]\d|2[0-3]):[0-5]\d$/, "The schedule time should have the format HH:MM"),
  frequency: number()
    .required("Meet frequency is required")
    .positive("Meet frequency should be a positive value")
    .integer("Meet frequency should be an integer value"),
  periodicity: string().required("Meet periodicity is required"),
  repeat: array().of(string()),
  ending: object({
    type: string().required("Ending type is required"),
    on: date().when("type", {
      is: type => type === "on",
      then: field => field
        .required("End date is required")
        .test(
          "is-ending-on-valid",
          "Ending on date must be between the development plan start and end dates",
          (ending_on, context) => {
            const plan = context.from[1].value;
            const start_date = new Date(plan?.start_date);
            const end_date = new Date(plan?.target_date);
            const date = getDateUTCAtMidnight(ending_on);
            
            return date >= start_date && date <= end_date;
          }
        ),
      otherwise: field => field.notRequired(),
    }),
    after: number().when("type", {
      is: type => type === "after",
      then: field => field
        .required("Occurences count is required")
        .positive("Occurences count should be a positive value")
        .integer("Occurences count should be an integer value"),
      otherwise: field => field.notRequired(),
    }),
  }).required()
}).required();

export default planSchema;